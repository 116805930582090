function recalcReviewHeights() {
    const reviewsContainers = document.querySelectorAll(".reviews-container");

    reviewsContainers.forEach((reviewsContainer) => {
        const lang = reviewsContainer.getAttribute("data-lang") || "en";
        const toggleLabels = getToggleButtonLabels(lang);

        const reviewDivs = reviewsContainer.querySelectorAll(".review");

        reviewDivs.forEach((reviewDiv) => {
            const reviewTextElem = reviewDiv.querySelector(".review-text");
            const toggleBtn = reviewDiv.querySelector(".toggle-readmore");

            heightCheck(reviewTextElem, toggleBtn, toggleLabels);
        });
    });
}

function heightCheck(reviewTextElem, toggleBtn, toggleLabels) {
    reviewTextElem.classList.remove("collapsed");
    toggleBtn.style.display = "none";

    const fullHeight = reviewTextElem.scrollHeight;

    if (fullHeight > 200) {
        reviewTextElem.classList.add("collapsed");
        toggleBtn.textContent = toggleLabels.readMore;
        toggleBtn.style.display = "block";
    } else {
        toggleBtn.style.display = "none";
    }
}

function getToggleButtonLabels(lang) {
    switch (lang) {
      case "de":
        return { readMore: "Mehr lesen", readLess: "Weniger lesen" };
      case "en":
        return { readMore: "Read more", readLess: "Read less" };
      default:
        return { readMore: "Read more", readLess: "Read less" };
    }
}

async function loadPlaceReviews() {
    function getStarRating(rating) {
      let starsHTML = "";
      for (let i = 0; i < rating; i++) {
        starsHTML += '<span class="star full">★</span>';
      }
      for (let i = 0; i < (5 - rating); i++) {
        starsHTML += '<span class="star empty">☆</span>';
      }
      return starsHTML;
    }

    const reviewsContainers = document.querySelectorAll(".reviews-container");
  
    reviewsContainers.forEach(async (reviewsContainer) => {
      try {
        const lang = reviewsContainer.getAttribute("data-lang") || "en";
        const toggleLabels = getToggleButtonLabels(lang);
  
        const place = new google.maps.places.Place({
          id: "ChIJBeRx2kMTlkcRsXSON_YMVUE",
          requestedLanguage: lang,
        });
  
        await place.fetchFields({ fields: ["reviews", "rating", "userRatingCount"] });

        if (place.rating && place.userRatingCount) {
            const googleReviews = document.getElementById("google-reviews-" + lang);
            const placeRating = googleReviews.querySelector('.rating');
            const placeUserRatingCount = googleReviews.querySelector('.userRatingCount');

            placeRating.textContent = place.rating.toFixed(1);
            placeUserRatingCount.textContent = place.userRatingCount;
        }

        if (place.reviews && place.reviews.length > 0) {
          reviewsContainer.innerHTML = "";
          place.reviews
            .filter(review => review.rating >= 4)
            .forEach(review => {
              const reviewDiv = document.createElement("div");
              reviewDiv.className = "review";
              reviewDiv.innerHTML = `
                <div class="review-header">
                  <a href="${review.authorAttribution.uri.replace('/reviews', '/place/ChIJBeRx2kMTlkcRsXSON_YMVUE')}" target="_blank"><img src="${review.authorAttribution.photoURI}" alt="${review.authorAttribution.displayName}" /></a>
                  <a href="${review.authorAttribution.uri.replace('/reviews', '/place/ChIJBeRx2kMTlkcRsXSON_YMVUE')}" target="_blank">${review.authorAttribution.displayName}</a>
                </div>
                <div class="review-meta">
                  <span class="review-rating">${getStarRating(review.rating)}</span>
                  <span class="review-time">${review.relativePublishTimeDescription}</span>
                </div>
                <p class="review-text">${review.text}</p>
                <button class="toggle-readmore">${toggleLabels.readMore}</button>
              `;
              reviewsContainer.appendChild(reviewDiv);
    
              const reviewTextElem = reviewDiv.querySelector(".review-text");
              const toggleBtn = reviewDiv.querySelector(".toggle-readmore");

              setTimeout(() => {
                  heightCheck(reviewTextElem, toggleBtn, toggleLabels);
              }, 0);
    
              toggleBtn.addEventListener("click", function () {
                if (reviewTextElem.classList.contains("collapsed")) {
                  reviewTextElem.classList.remove("collapsed");
                  this.textContent = toggleLabels.readLess;
                } else {
                  reviewTextElem.classList.add("collapsed");
                  this.textContent = toggleLabels.readMore;
                }
              });
          });
        } else {
          reviewsContainer.innerHTML = "<p>No reviews available.</p>";
        }
      } catch (error) {
        console.error("Error loading place reviews:", error);
        reviewsContainer.innerHTML = "<p>Failed to load reviews.</p>";
      }
    });
}

window.loadPlaceReviews = loadPlaceReviews;
window.recalcReviewHeights = recalcReviewHeights;
